










































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import moment, { Moment } from 'moment'
import '@fullcalendar/core/vdom'
import FullCalendar, {
  CalendarOptions,
  EventApi,
  DateSelectArg,
  EventClickArg
} from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import listPlugin from '@fullcalendar/list'
import CalendarService from '@/services/CalendarService'
import { EPlanType, UserModel, EIconType } from '@/models'
import store from '@/store'
import GroupService from '@/services/GroupService'
import PreviewText from '@/helpers/PreviewText'
import CalendarColor from '@/helpers/CalendarColor'
//@ts-ignore
import _ from 'lodash'
import AuthService from '@/services/AuthService'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/store/modules/Auth'
import i18n from '@/lang'
const AuthModule = getModule(Auth, store)

@Component({ components: { FullCalendar } })
export default class MyCalendar extends Vue {
  private currentUser: UserModel = new UserModel()
  private checkView: string = this.hanldeViewType()
  private titleCalendar: string = ''
  private currentDate: Date = new Date()
  private lstOption: any[] = []
  private list_events: any[] = []
  private textDropdown: string = ''
  private chooseType: string = ''
  private events: any[] = []
  private today: any = moment()
  private dow = this.$store.state.setting.setting.setting_display
    .first_day_of_week
  private addMonth: number = 1
  private subtractMonth: number = -1
  private currentEvents: EventApi[] = []
  private eIconType: any = EIconType
  private calendarColor: CalendarColor = new CalendarColor()
  private calendarOptions: CalendarOptions = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
      momentPlugin
    ],
    headerToolbar: false,
    visibleRange: currentDate => {
      return {
        start: moment(currentDate).toDate(),
        end: moment(currentDate)
          .add(6, 'days')
          .toDate()
      }
    },
    longPressDelay: 0,
    views: {
      list: {
        titleFormat: `${this.$t('common.date_format.yyyy_mm_dd')}（dd）`,
        listDayFormat: 'MM/DD（dd）',
        displayEventTime: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false
        },
        eventDidMount: (info: any) => {
          const dotEl = info.el.getElementsByClassName('fc-list-event-time')[0]
          if (dotEl) {
            if (dotEl.innerText === '00:00') {
              dotEl.innerText = this.$t(
                'calendar.display.allday_content'
              ) as string
            }
          }
        }
      },
      timeGrid: {
        titleFormat: `${this.$t('common.date_format.yyyy_mm_dd')}（dd）`,
        dayHeaderContent: arg => {
          return {
            html:
              moment(arg.date).format('MM/DD') +
              '<br>' +
              moment(arg.date)
                .lang(store.state.setting.setting.setting_language.code)
                .format('dd')
          }
        }
      },
      dayGrid: {
        titleFormat: this.$t('common.date_format.yyyy_mm')
      },
      dayGridTwoWeeks: {
        type: 'list',
        duration: { week: 1 },
        dateIncrement: { day: 1 },
        visibleRange: currentDate => {
          return {
            start: moment(currentDate)
              .startOf('week')
              .toDate(),
            end: moment(currentDate)
              .endOf('week')
              .toDate()
          }
        }
      }
    },
    initialView: 'list',
    noEventsContent: this.$t('calendar.display.no_event_to_display'),
    noEventsClassNames: 'no_event',
    eventSources: [],
    events: [],
    editable: false,
    selectable: true,
    selectMirror: true,
    nextDayThreshold: '00:00',
    dayMaxEvents: true,
    weekends: true,
    displayEventEnd: true,
    locale: store.state.setting.setting.setting_language.code,
    height: 'auto',
    slotEventOverlap: false,
    eventBackgroundColor: '',
    displayEventTime: false,
    select: this.handleDateSelect,
    // eventClick: this.handleEventClick,
    eventsSet: this.handleEvents,
    slotLabelFormat: { hour: '2-digit', minute: '2-digit' },
    allDayContent: this.$t('calendar.display.allday_content'),
    dayCellContent: arg => {
      arg.dayNumberText = arg.dayNumberText.replace('日', '')
    },
    dayCellDidMount: info => {
      info.dayNumberText = info.dayNumberText.replace('日', '')
      if (info.view.type === 'dayGrid') {
        this.list_events = this.calendarOptions.events as any
        const todayJapanEvent = this.list_events.filter((item: any) => {
          return (
            moment(info.date).date() >= moment(item.start).date() &&
            moment(info.date).date() < moment(item.end).date() &&
            item.extendedProps.is_holiday
          )
        })
        if (todayJapanEvent.length) {
          info.el.classList.add('title_holiday')
        }
      }
    },

    dayHeaderDidMount: info => {
      if (info.view.type === 'list') {
        info.el.classList.add('fc-list-day-custom')
      }

      if (info.view.type !== 'dayGrid') {
        this.list_events = this.calendarOptions.events as any
        const todayJapanEvent = this.list_events.filter((item: any) => {
          return (
            moment(info.date).date() >= moment(item.start).date() &&
            moment(info.date).date() < moment(item.end).date() &&
            item.extendedProps.is_holiday
          )
        })
        if (todayJapanEvent.length) {
          info.el.classList.add('title_holiday')
        }
      }
    }
  }

  get calendar() {
    return (this.$refs.fullCalendar as InstanceType<
      typeof FullCalendar
    >).getApi()
  }

  created() {
    this.lstOption = [
      {
        value: 'all',
        name: this.$t('calendar.display.dropdown.all').toString()
      },
      {
        value: 'my-calendar',
        name: this.$t('calendar.display.dropdown.my_calendar').toString()
      }
    ]
    this.chooseType = this.lstOption[0].value
    this.textDropdown = this.lstOption[0].name
  }

  mounted() {
    this.init()
    this.changeView()
  }

  async init() {
    await this.setDayOfWeek()
    this.getAllGroupsOfCurrentUser()
    this.currentDate = moment()
      .startOf('week')
      .format('YYYY-MM-DD') as any
  }

  @Watch('$route.name')
  async changeView() {
    this.changeViewCalendar(this.hanldeViewType())
    await this.getListEventInWeek()
    this.changeViewTable('month')
    // this.checkView === 'dayGrid' ? this.nowWeek() : this.nowDay()
  }

  hanldeViewType() {
    const viewType = this.$route.name?.split('-')[1]
    switch (viewType) {
      case 'day':
        return 'timeGridDay'
      case 'week':
        return 'timeGrid'
      case 'month':
        return 'dayGrid'
      default:
        return 'list'
    }
  }

  async setDayOfWeek() {
    return moment.updateLocale('ja', {
      week: {
        dow: this.dow
      }
    })
  }

  handleText(text: string) {
    return PreviewText.covertToPreviewText(text, 20).text
  }

  @Watch('chooseType')
  async updateListEventView() {
    this.currentDate = this.calendar.view.activeStart
    await this.getListEventInWeek()
    this.changeViewTable()
  }

  private chooseTypeSetting(value: any) {
    let optionChoose = this.lstOption.find((item: any) => {
      return item.value === value
    })
    if (optionChoose) {
      this.textDropdown = optionChoose.name
      this.chooseType = optionChoose.value
    }
  }

  async nextMonth() {
    let startDate = moment()
      .add(this.addMonth - 1, 'M')
      .endOf('month')
    let endDate = moment()
      .add(this.addMonth + 1, 'M')
      .startOf('month')
    this.handleStartOfMonth(startDate, endDate)
    const activeStart = startDate.startOf('week').toDate()
    this.currentDate = activeStart
    await this.getListEventInWeek()
    this.calendar.setOption('visibleRange', {
      start: activeStart,
      end: endDate.endOf('week').toDate()
    })
    this.addMonth++
    this.subtractMonth++
    this.today = this.calendar.view.activeStart
    this.titleCalendar = moment(this.today)
      .endOf('week')
      .format(this.$t('common.date_format.yyyy_mm') as any)
    this.changeViewTable('month')
  }

  async prevMonth() {
    let startDate = moment()
      .add(this.subtractMonth - 1, 'M')
      .endOf('month')
    let endDate = moment()
      .add(this.subtractMonth + 1, 'M')
      .startOf('month')
    this.handleStartOfMonth(startDate, endDate)
    const activeStart = startDate.startOf('week').toDate()
    this.currentDate = activeStart
    await this.getListEventInWeek()
    this.calendar.setOption('visibleRange', {
      start: activeStart,
      end: endDate.endOf('week').toDate()
    })
    this.addMonth--
    this.subtractMonth--
    this.today = this.calendar.view.activeStart
    this.titleCalendar = moment(this.today)
      .endOf('week')
      .format(this.$t('common.date_format.yyyy_mm') as any)
    this.changeViewTable('month')
  }

  async nextDay(view: string) {
    const activeStart = moment(this.calendar.view.activeStart)
      .add(1, 'days')
      .toDate()
    this.currentDate = activeStart
    await this.getListEventInWeek()
    if (view == 'day' && this.calendar.view.type == 'timeGridDay') {
      this.calendar.gotoDate(
        moment(this.calendar.view.activeStart)
          .add(1, 'days')
          .toDate()
      )
    } else {
      this.calendar.setOption('visibleRange', {
        start: activeStart,
        end: moment(this.calendar.view.activeEnd)
          .add(1, 'days')
          .toDate()
      })
    }
    this.titleCalendar = this.calendar.view.title
    this.changeViewTable()
    this.today = this.calendar.view.activeStart
  }

  async prevDay(view: string) {
    const activeStart = moment(this.calendar.view.activeStart)
      .add(-1, 'days')
      .toDate()
    this.currentDate = activeStart
    await this.getListEventInWeek()
    if (view == 'day' && this.calendar.view.type == 'timeGridDay') {
      this.calendar.gotoDate(
        moment(this.calendar.view.activeStart)
          .add(-1, 'days')
          .toDate()
      )
    } else {
      this.calendar.setOption('visibleRange', {
        start: activeStart,
        end: moment(this.calendar.view.activeEnd)
          .add(-1, 'days')
          .toDate()
      })
    }
    this.changeViewTable()
    this.titleCalendar = this.calendar.view.title
    this.today = this.calendar.view.activeStart
  }

  async nextWeek(view: string) {
    let activeStart: any = ''
    if (view == 'month') {
      activeStart = moment(this.calendar.view.activeStart)
        .startOf('week')
        .add(1, 'w')
        .toDate()
    } else if (view == 'week' && this.calendar.view.type == 'timeGridDay') {
      activeStart = moment(this.calendar.view.activeStart)
        .add(1, 'w')
        .toDate()
    } else {
      activeStart = moment(this.calendar.view.activeEnd).toDate()
    }
    //set current date
    this.currentDate = activeStart

    //call API
    await this.getListEventInWeek()

    //change view
    if (view == 'week' && this.calendar.view.type == 'timeGridDay') {
      this.calendar.gotoDate(activeStart)
    } else if (view == 'month') {
      this.calendar.setOption('visibleRange', {
        start: activeStart,
        end: moment(this.calendar.view.activeEnd)
          .startOf('week')
          .add(1, 'w')
          .toDate()
      })
      this.markGrayOutForOutsideMonth()
    } else {
      this.calendar.setOption('visibleRange', {
        start: activeStart,
        end: moment(this.calendar.view.activeEnd)
          .add(1, 'w')
          .toDate()
      })
    }

    this.today = this.calendar.view.activeStart
    view !== 'month'
      ? (this.titleCalendar = this.calendar.view.title)
      : (this.titleCalendar = moment(this.today)
          .endOf('week')
          .format(this.$t('common.date_format.yyyy_mm') as any))
    this.changeViewTable('month')
  }

  async prevWeek(view: string) {
    let activeStart: any = ''
    if (view == 'month') {
      activeStart = moment(this.calendar.view.activeStart)
        .startOf('week')
        .add(-1, 'w')
        .toDate()
    } else if (view == 'week' && this.calendar.view.type == 'timeGridDay') {
      activeStart = moment(this.calendar.view.activeStart)
        .add(-1, 'w')
        .toDate()
    } else {
      activeStart = moment(this.calendar.view.activeStart)
        .add(-1, 'w')
        .toDate()
    }
    this.currentDate = activeStart
    await this.getListEventInWeek()

    if (view == 'week' && this.calendar.view.type == 'timeGridDay') {
      this.calendar.gotoDate(activeStart)
    } else if (view == 'month') {
      this.calendar.setOption('visibleRange', {
        start: activeStart,
        end: moment(this.calendar.view.activeEnd)
          .startOf('week')
          .add(-1, 'w')
          .toDate()
      })
      this.markGrayOutForOutsideMonth()
    } else {
      this.calendar.setOption('visibleRange', {
        start: activeStart,
        end: moment(this.calendar.view.activeStart).toDate()
      })
    }
    this.today = this.calendar.view.activeStart
    view !== 'month'
      ? (this.titleCalendar = this.calendar.view.title)
      : (this.titleCalendar = moment(this.today)
          .endOf('week')
          .format(this.$t('common.date_format.yyyy_mm') as any))
    this.changeViewTable('month')
  }

  async nowWeek() {
    const activeStart = moment()
      .startOf('week')
      .toDate()
    this.currentDate = activeStart
    await this.getListEventInWeek()
    this.calendar.setOption('visibleRange', {
      start: activeStart,
      end: moment()
        .startOf('week')
        .add(5, 'week')
        .toDate()
    })
    this.addMonth = 1
    this.subtractMonth = -1
    this.markGrayOutForOutsideMonth()
    this.today = this.calendar.view.activeStart
    this.titleCalendar = moment(this.today)
      .endOf('week')
      .format(this.$t('common.date_format.yyyy_mm') as any)
    this.changeViewTable('month')
  }

  async nowDay() {
    const activeStart = moment().toDate()
    this.currentDate = activeStart
    await this.getListEventInWeek()
    if (this.calendar.view.type == 'timeGridDay') {
      this.calendar.gotoDate(activeStart)
    } else {
      this.calendar.setOption('visibleRange', {
        start: activeStart,
        end: moment()
          .add(6, 'days')
          .toDate()
      })
    }
    this.titleCalendar = this.calendar.view.title
    this.changeViewTable()
    this.today = this.calendar.view.activeStart
  }

  async changeViewCalendar(viewType: string) {
    if (viewType === 'dayGrid') {
      this.setDayOfWeek()
    }
    this.checkView = viewType
    this.$emit('update-view', this.checkView)
    if (viewType == 'list' || viewType == 'timeGrid') {
      this.calendar.setOption('visibleRange', {
        start: moment(this.today)
          .startOf('day')
          .toDate(),
        end: moment(this.today)
          .startOf('day')
          .add(7, 'days')
          .toDate()
      })
    }
    if (viewType === 'timeGridDay') {
      this.calendar.setOption('visibleRange', {
        start: moment(this.today)
          .startOf('day')
          .toDate(),
        end: moment(this.today)
          .startOf('day')
          .add(1, 'days')
          .toDate()
      })
    }

    if (viewType === 'dayGrid') {
      let startDate = moment(this.today).startOf('week')
      let endDate = moment(this.today)
        .startOf('week')
        .add(5, 'week')
      this.calendar.setOption('visibleRange', {
        start: startDate.toDate(),
        end: endDate.toDate()
      })
      this.markGrayOutForOutsideMonth()
      this.addMonth = 1
      this.subtractMonth = -1
    }
    this.calendar.changeView(viewType)
    this.currentDate = this.calendar.view.activeStart
    this.titleCalendar =
      viewType === 'dayGrid'
        ? (this.titleCalendar = moment(this.today)
            .endOf('week')
            .format(this.$t('common.date_format.yyyy_mm') as any))
        : this.calendar.view.title
  }

  /**
   * Hanlde start date of month is the start date of week
   */
  handleStartOfMonth(startDate: Moment, endDate: Moment) {
    if (startDate.endOf('week').month() === startDate.startOf('week').month()) {
      startDate = startDate.add(1, 'week')
    }
  }

  /**
   * Mark day of orther month to grayout
   */
  private markGrayOutForOutsideMonth() {
    // let numberDiff = moment(this.calendar.view.activeEnd)
    //   .startOf('week')
    //   .add(1, 'w')
    //   .diff(
    //     moment(this.calendar.view.activeStart)
    //       .startOf('week')
    //       .add(1, 'w'),
    //     'days'
    //   )
    // let number = Math.floor(numberDiff / 2)
    let centerMonth
    this.calendar.setOption('dayCellClassNames', arg => {
      centerMonth =
        moment(arg.view.activeStart)
          .add(6, 'days')
          .month() + 1
      let currentDay = moment(arg.date).month() + 1
      if (
        (arg.isOther && (arg.isFuture || arg.isPast)) ||
        centerMonth > currentDay ||
        centerMonth < currentDay
      ) {
        return 'fc-day-other'
      }
      return ''
    })
  }

  private changeViewTable(type: string = '') {
    if (type === '') {
      this.titleCalendar = this.calendar.view.title
    }
    let element2 = document.querySelectorAll('.fc-list-day-custom th')
    let element = document.querySelectorAll('.fc-list-table tr')
    let list_event_time = document.querySelectorAll('.fc-list-event-time')
    let list_event_title = document.querySelectorAll('.fc-list-event-title')
    let count = 0
    let temp = []
    for (let index = 0; index < element.length; index++) {
      count++
      if (element[index].classList.contains('fc-list-day-custom')) {
        temp.push(count)
      }
    }
    for (let index2 = 0; index2 < element2.length; index2++) {
      if (index2 == element2.length - 1) {
        ;(element2[index2] as any).rowSpan = element.length - temp[index2] + 1
      } else {
        ;(element2[index2] as any).rowSpan = temp[index2 + 1] - temp[index2]
      }
      ;(element2[index2] as any).style.verticalAlign = 'middle'
      ;(element2[index2] as any).style.width = '100px'
      ;(element2[index2] as any).colSpan = 1
    }
    for (let element = 0; element < list_event_time.length; element++) {
      ;(list_event_time[element] as any).style.borderTopWidth = '0px'
    }
    for (let element = 0; element < list_event_title.length; element++) {
      ;(list_event_title[element] as any).style.borderTopWidth = '0px'
    }
  }

  private handleDateSelect(selectInfo: DateSelectArg) {
    this.$router.push({
      name: 'calendar-create',
      params: {
        event_start_date: selectInfo.startStr,
        event_end_date: selectInfo.startStr
      }
    })
  }

  private getEventRoute(event: EventApi) {
    if (event.id && !event.extendedProps.is_holiday) {
      const chooseDate = moment(event.start).format('YYYY/MM/DD')
      // this.$router.push({
      //   name: 'calendar-preview',
      //   params: { id: event.id, chooseDate: chooseDate }
      // })
      return {
        name: 'calendar-preview',
        params: { id: event.id },
        query: { chooseDate: chooseDate },
      }
    }
    return {}
  }

  private handleEvents(events: EventApi[]) {
    this.currentEvents = events
  }

  private async getListEventInWeek() {
    let dateSearchEvent: any = {}
    dateSearchEvent.optionDisplay = this.chooseType
    if (this.calendar.view.type === 'dayGrid') {
      dateSearchEvent.startDate = moment(this.currentDate).format('YYYY-MM-DD')
      dateSearchEvent.endDate = moment(this.currentDate)
        .add(6, 'week')
        .format('YYYY-MM-DD')
    } else if (this.calendar.view.type === 'timeGridDay') {
      dateSearchEvent.startDate = moment(this.currentDate).format('YYYY-MM-DD')
      dateSearchEvent.endDate = moment(this.currentDate).format('YYYY-MM-DD')
    } else {
      dateSearchEvent.startDate = moment(this.currentDate).format('YYYY-MM-DD')
      dateSearchEvent.endDate = moment(this.currentDate)
        .add(6, 'day')
        .format('YYYY-MM-DD')
    }
    return CalendarService.getEventCalendarInWeek(dateSearchEvent)
      .then((res: any) => {
        if (res.status === 200) {
          this.setLstEventCalendar(res.data)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  getAllGroupsOfCurrentUser() {
    GroupService.getAllGroupCompact().then(response => {
      if (response.status === 200) {
        let list_groups = response.data.data.map((item: any) => {
          return {
            value: item.id,
            name: item.name
          }
        })
        this.lstOption = [...this.lstOption, ...list_groups]
      }
    })
  }

  private setLstEventCalendar(data: any) {
    let lstEvent: any[] = []
    data.forEach((eventCalendar: any) => {
      let event: any = {}
      event.id = eventCalendar.id
      event.title = eventCalendar.title
      event.backgroundColor = eventCalendar.event_color
      event.start = eventCalendar.event_start_date
      event.end = eventCalendar.event_end_date
      event.extendedProps = {
        icon_type: eventCalendar.icon_type,
        plan_type: eventCalendar.plan_type,
        is_holiday: eventCalendar.is_holiday
      }

      event.textColor = this.calendarColor.getTextColor(
        eventCalendar.event_color
      )

      if (
        moment(event.end).diff(event.start, 'days') > 0 &&
        eventCalendar.plan_type !== EPlanType.NORMAL
      ) {
        event.allDay = true
        if (
          this.calendar.view.type === 'dayGrid' &&
          eventCalendar.plan_type === EPlanType.DURRATION
        ) {
          event.allDay = true
        }
      }

      if (event.extendedProps.is_holiday) {
        event.title =
          this.$store.state.setting.setting.setting_language.code === 'ja'
            ? eventCalendar.title_ja
            : eventCalendar.title_en
        event.backgroundColor = 'transparent'
      }

      if (
        this.calendar.view.type === 'dayGrid' &&
        event.extendedProps.is_holiday
      ) {
        event.allDay = true
        event.display = 'background'
      }
      lstEvent.push(event)
    })

    this.calendarOptions.events = lstEvent
  }
}
